var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _c(
      "div",
      {
        staticClass: "form-horizontal form-validate-jquery",
        attrs: { id: "detailsForm" }
      },
      [
        _c("div", { staticClass: "panel panel-flat bd-n" }, [
          _c("div", { staticClass: "panel-heading" }, [
            _c("h6", { staticClass: "panel-title" }, [
              _c("b", [
                _vm._v(
                  "프레임 정보 (" +
                    _vm._s(this.detailsItem.cctvNm) +
                    " - " +
                    _vm._s(this.detailsItem.cctvInstallPlaceCont) +
                    ")"
                )
              ])
            ])
          ]),
          _c("div", { staticClass: "panel-body dp-flex" }, [
            _c(
              "div",
              {
                staticClass: "border",
                staticStyle: {
                  width: "820px",
                  height: "550px",
                  "overflow-y": "auto"
                }
              },
              _vm._l(_vm.detailsItem.inqDangerOccurFrameImgOutVOs, function(
                item,
                index
              ) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticStyle: {
                      display: "inline-block",
                      "text-align": "center"
                    }
                  },
                  [
                    index < 30
                      ? _c("div", [
                          _c("img", {
                            staticStyle: {
                              width: "200px",
                              height: "112.5px",
                              "object-fit": "contain",
                              padding: "5px",
                              display: "block"
                            },
                            attrs: { src: item.imgData }
                          }),
                          _c("span", [_vm._v(_vm._s(item.imgSaveDtm))])
                        ])
                      : _vm._e()
                  ]
                )
              }),
              0
            ),
            _c(
              "div",
              {
                staticClass: "border",
                staticStyle: {
                  width: "300px",
                  height: "550px",
                  "margin-left": "20px"
                }
              },
              [
                _c("div", { staticClass: "rhtBox border-bottom" }, [
                  _vm._m(1),
                  _c(
                    "div",
                    _vm._l(_vm.detailsItem.objects, function(object) {
                      return _c(
                        "div",
                        { key: object.id },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "font-size": "17px",
                                "margin-bottom": "10px",
                                "padding-left": "15px",
                                "margin-top": "30px",
                                "margin-left": "20px"
                              }
                            },
                            [_vm._v(_vm._s(object.name))]
                          ),
                          _vm._l(object.targets, function(item) {
                            return _c(
                              "li",
                              {
                                key: item,
                                staticStyle: {
                                  "margin-left": "20px",
                                  "padding-left": "30px"
                                }
                              },
                              [_vm._v(" " + _vm._s(item) + " ")]
                            )
                          })
                        ],
                        2
                      )
                    }),
                    0
                  )
                ]),
                _c("div", { staticClass: "rhtBox" }, [
                  _vm._m(2),
                  _c(
                    "div",
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "padding-top": "30px",
                            "padding-left": "35px"
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.detailsItem.dangerOccurDtm) +
                              " : 위험 발생"
                          )
                        ]
                      ),
                      _vm._l(_vm.detailsItem.inqDangerOccurActnOutVOs, function(
                        item,
                        index
                      ) {
                        return _c("div", { key: index }, [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "margin-top": "30px",
                                "margin-left": "20px",
                                "padding-left": "15px"
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(item.actnDtm) +
                                  " : " +
                                  _vm._s(item.videoDangerActnDvsnCdNm)
                              )
                            ]
                          )
                        ])
                      })
                    ],
                    2
                  )
                ])
              ]
            )
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { id: "pageTitle" } }, [
      _c("b", [_vm._v("위험 발생이력 상세보기")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("h3", [_vm._v("조치 대상")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("h3", [_vm._v("조치 이력")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }