<template>
	<div>
		<div id="pageTitle">
			<b>위험 발생이력 상세보기</b>
		</div>
		<div id="detailsForm" class="form-horizontal form-validate-jquery">
			<div class="panel panel-flat bd-n">
				<div class="panel-heading">
					<h6 class="panel-title">
						<b>프레임 정보 ({{ this.detailsItem.cctvNm }} - {{ this.detailsItem.cctvInstallPlaceCont }})</b>
					</h6>
				</div>

				<div class="panel-body dp-flex">
					<div class="border" style="width: 820px; height: 550px; overflow-y: auto">
						<div style="display: inline-block; text-align: center" v-for="(item, index) in detailsItem.inqDangerOccurFrameImgOutVOs" :key="index">
							<div v-if="index < 30">
								<img :src="item.imgData" style="width: 200px; height: 112.5px; object-fit: contain; padding: 5px; display: block" />
								<span>{{ item.imgSaveDtm }}</span>
							</div>
						</div>
					</div>
					<div class="border" style="width: 300px; height: 550px; margin-left: 20px">
						<div class="rhtBox border-bottom">
							<div><h3>조치 대상</h3></div>
							<div>
								<div v-for="object in detailsItem.objects" :key="object.id">
									<div style="font-size: 17px; margin-bottom: 10px; padding-left: 15px; margin-top: 30px; margin-left: 20px">{{ object.name }}</div>
									<li v-for="item in object.targets" :key="item" style="margin-left: 20px; padding-left: 30px">
										{{ item }}
									</li>
								</div>
							</div>
						</div>
						<div class="rhtBox">
							<div><h3>조치 이력</h3></div>
							<div>
								<div style="padding-top: 30px; padding-left: 35px">{{ detailsItem.dangerOccurDtm }} : 위험 발생</div>
								<div v-for="(item, index) in detailsItem.inqDangerOccurActnOutVOs" :key="index">
									<div style="margin-top: 30px; margin-left: 20px; padding-left: 15px">{{ item.actnDtm }} : {{ item.videoDangerActnDvsnCdNm }}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import apiIndex from '../../api/index';
const mainUrl = apiIndex.main;
const dangerOccurUrl = apiIndex.dangerOccur;
import _ from 'lodash';

let axiosExtention;

export default {
	data: () => ({
		windowSize: [1216, 768],
		dataIndex: 0,
		cctvOptions: [],
		dangerOccurList: [],
		dangerOccurTarget: {},
		detailsItem: {},
		errorMsg: '위험 보고 정보가 올바르지 않습니다',
	}),
	mounted() {
		this.dataIndex = this.$route.query.index ? parseInt(this.$route.query.index) : 0;

		let content = $('.content');
		if (content) {
			content.css('width', '100%');
			content.css('padding-top', '10px');
		}

		let that = this;

		// fixed popup window size 1200*600
		$(window).resize(function () {
			window.resizeTo(that.windowSize[0], that.windowSize[1]);
		});

		axiosExtention = this.$jarvisExtention.axiosExtention;

		this.init();
	},
	methods: {
		init() {
			this.$axios
				.post(mainUrl.inqDangerOccurReport, {})
				.then(r => {
					const data = r.data;

					if (data.cctvOptions) this.cctvOptions = data.cctvOptions;

					if (data.dangerOccurReportList) {
						this.dangerOccurList = data.dangerOccurReportList;

						if (this.dangerOccurList.length > this.dataIndex) {
							this.dangerOccurTarget = this.dangerOccurList[this.dataIndex];
							this.getItemDetailInfo();
						} else {
							alert(this.errorMsg);
							window.close();
						}
					}
				})
				.catch(axiosExtention.buildErrorHandler());
		},
		getItemDetailInfo() {
			let selectedItem = this.dangerOccurTarget;

			if (!_.isEmpty(selectedItem)) {
				this.$axios
					.post(dangerOccurUrl.inqOneDangerOccur, {
						siteId: selectedItem.siteId,
						cctvId: selectedItem.cctvId,
						objectId: selectedItem.objectId,
						targetId: selectedItem.targetId,
						dangerOccurObjectSeq: selectedItem.dangerOccurObjectSeq,
						strtFrameImgSeq: selectedItem.strtFrameImgSeq,
						endFrameImgSeq: selectedItem.endFrameImgSeq,
					})
					.then(
						function (response) {
							this.detailsItemOriginal = $.extend(true, {}, response.data);
							this.detailsItem = $.extend(true, {}, response.data);
							this.detailsItem.dangerOccurDtm = selectedItem.dangerOccurDtm;
							this.detailsItem.objectId = selectedItem.objectId;
							this.detailsItem.targetId = selectedItem.targetId;
							this.detailsItem.dangerOccurObjectSeq = selectedItem.dangerOccurObjectSeq;
							this.detailsItem.videoDangerActnDvsnCd = selectedItem.videoDangerActnDvsnCd;
							this.detailsItem.actnReasonCont = selectedItem.actnReasonCont;

							this.detailsItem.objects = [];
							this.detailsItem.inqDangerOccurFrameImgOutVOs.forEach(e => {
								e.imgData = 'data:image/jpg;base64,' + e.imgData;
							});

							this.detailsItem.inqDangerOccurActnOutVOs.forEach(e => {
								this.options.videoDangerActnDvsnCdOption.forEach(e1 => {
									if (e.videoDangerActnDvsnCd === e1.value) {
										e.videoDangerActnDvsnCdNm = e1.text;
										return;
									}
								});
							});

							this.detailsItem.inqDangerOccurFrameImgDetailOutVOs.forEach(e => {
								let isDuplicate = false;
								let objectIndex = 0;

								this.detailsItem.objects.forEach((e1, index) => {
									if (e1.name === e.objectNm) {
										// 해당 객체 이름이 존재하는지 찾음
										isDuplicate = true; // 같은 이름이 존재한다면
										objectIndex = index;
									}
								});

								if (!isDuplicate) {
									// 같은 이름이 존재하지 않으면
									let temp = { name: e.objectNm, targets: [] };
									temp.targets.push(e.targetNm);

									this.detailsItem.objects.push(temp);
								} else {
									// 같은 이름이 존재하면
									this.detailsItem.objects[objectIndex].targets.push(e.targetNm);
								}
							});

							const cctvInfo = this.cctvOptions.filter(item => item.cctvId == this.detailsItem.cctvId)[0];

							if (cctvInfo) {
								this.$set(this.detailsItem, 'cctvNm', cctvInfo.cctvNm);
								this.$set(this.detailsItem, 'cctvInstallPlaceCont', cctvInfo.cctvInstallPlaceCont);
							}
						}.bind(this),
					)
					.catch(axiosExtention.buildErrorHandler());
			} else {
				alert(this.errorMsg);
				window.close();
			}
		},
	},
};
</script>

<style>
.border {
	border-radius: 3px;
	border: 1px solid #ddd;
	float: left;
}
.dp-flex {
	display: flex;
}
.bd-n {
	border: none !important;
}
.rhtBox {
	height: 50%;
}
.rhtBox > div:first-child {
	background: #f9f9f9;
}
.rhtBox > div:last-child {
	overflow-y: auto;
	height: 81%;
}
h3 {
	font-weight: bold !important;
	margin: 0 !important;
	padding: 10px 10px 10px 15px;
}
.mBox {
	height: 135px;
	border-radius: 3px;
	border: 1px solid #ddd;
	margin-bottom: 50px;
}
.mBox > div:first-child {
	background: #f9f9f9;
}
.border-bottom {
	border-bottom: 1px solid #ddd !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
}
</style>
